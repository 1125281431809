<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="12"
      >
        <b-card title="PERSONALIZE AS FUNÇÕES DE SEU BOT">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>Primeiro escolha o grupo que voce vai editar..</span>
            </div>
          </b-alert>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="createGroup">
              <b-row>
                <!-- GRUPO -->
                <b-col cols="12">
                  <b-form-group
                    label="Grupo"
                    label-for="link-group"
                  >
                    <validation-provider
                      name="Grupo"
                      rules="required"
                    >
                      <b-form-select
                        id="tipogrupo"
                        v-model="grupo"
                        :disabled="grupodisabled"
                      >
                        <option
                          v-for="option in options"
                          :key="option.id"
                          :value="option.id"
                        >
                          {{ option.name }} => {{ option.link }}
                        </option>
                      </b-form-select>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <hr>
          <b-row>
            <!-- left align -->
            <b-col
              v-if="quadrados.tabela"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!TABELA"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !TABELA </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perTabela()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
            <b-col
              v-if="quadrados.anuncio"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!ANUNCIO"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !ANUNCIO </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perAnuncio()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
            <b-col
              v-if="quadrados.info"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!INFO"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !INFO </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perInfo()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
            <b-col
              v-if="quadrados.info"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!BV"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !BV (boas-vindas) </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perBv()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
            <b-col
              v-if="quadrados.info"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!MSGAUT1"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !MSGAUT1 (temporizador) </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perMsgAut()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
            <b-col
              v-if="quadrados.info"
              md="6"
              lg="4"
            >
              <b-card
                style="border: 1px solid"
                title="!MSGAUT2"
                class="mb-3"
              >
                <b-card-text> Personalize o comando !MSGAUT2 (temporizador) </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="perMsgAut2()"
                >
                  Personalizar
                </b-button>
              </b-card>
            </b-col>
          </b-row>

          <b-col
            v-if="atualizarfUNC"
            md="12"
            lg="12"
          >
            <h2>{{ nomeQuadradoSelecionado }}</h2>
            <b-alert
              variant="success"
              show
            >
              <div class="alert-body">
                <span>Preencha o campo de texto com a nova mensagem e clique em
                  Salvar</span>
              </div>
            </b-alert>
            <b-form @submit.prevent="atualizaFuncao()">
              <b-form-textarea
                id="textarea-default"
                v-model="texto"
                placeholder="Digite aqui o texto que será exibido no seu grupo."
                rows="8"
              /><br>
              <div v-if="nomeQuadradoSelecionado.includes('AUT')">
                <h5>Temporizador</h5>
                <b-form-input
                  id="timer"
                  v-model="temporizadorT"
                  disabled
                  autofocus
                /><br>
              </div>
              <h5>Quer inserir uma foto junto com o texto?</h5>
              <b-alert
                variant="dark"
                show
              >
                <div class="alert-body">
                  <span>Importe e visualize sua imagem</span>
                </div>
              </b-alert>
              <b-form-file
                id="extension"
                v-model="imagemUpload"
                placeholder="Importar Imagem..."
                drop-placeholder="Seleciona a Imagem..."
                no-drop
                accept=".jpg, .png, .gif, .jpeg"
              />
              <b-button
                variant="outline-primary"
                type="submit"
                @click.prevent="previewImage"
              >
                Visualizar Foto
              </b-button>
              <br><br>

              <b-img
                :src="base64"
                fluid
                alt=""
              />
              <b-button
                variant="outline-primary"
                @click="base64 = ''"
              >Remover Foto</b-button>
              <br><br>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn btn-outline-info waves-effect"
                @click="atualizaFuncao()"
              >SALVAR</b-button>
            </b-form>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable */
import {
  BCard,
  BCardText,
  BFormGroup,
  BForm,
  BRow,
  BButton,
  BCol,
  BAlert,
  BFormSelect,
  BFormFile,
  BImg,
  BFormTextarea,
  BFormInput
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import funcs from "@/services/func.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const user = JSON.parse(localStorage.getItem("user"));

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormFile,
    BCard,
    BFormSelect,
    BButton,
    BImg,
    BFormTextarea,
    BCardText,
    BAlert,
    BFormGroup,
    BForm,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      grupo: null,
      options: [],
      temporizadorT: 0,
      atualizarfUNC: false,
      grupodisabled: false,
      texto: "",
      base64: "",
      imagemUpload: null,
      nomeQuadradoSelecionado: "",

      quadrados: { tabela: true, anuncio: true, info: true, boasvindas: true },
    };
  },

  mounted() {
    this.fetchUserGroups();
  },

  methods: {
    fetchUserGroups() {
      funcs.fetchUserGroups(user.id).then((success) => {
        if (success.data[0]) {
          const returnRequest = success.data;
          this.options = returnRequest;
        }
      });
    },

    async previewImage() {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      if (this.imagemUpload.size >= 662515) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Opa!!",
            icon: "CoffeeIcon",
            variant: "primary",
            text: "Essa foto é muito grande, importe uma menor!",
          },
        });
        this.imagemUpload = null;
      } else {
        const arquivo = await toBase64(this.imagemUpload);
        console.log(arquivo);
        this.base64 = arquivo;
      }
    },

    verifyGroup() {
      if (this.grupo == null) {
        return false;
      } else {
        return true;
      }
    },

    perInfo() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.grupodisabled = true;
        this.nomeQuadradoSelecionado = "!INFO";
        this.getTexto();
      }
    },
    perBv() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.grupodisabled = true;
        this.nomeQuadradoSelecionado = "!BV";
        this.getTexto();
      }
    },
    perMsgAut() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.temporizadorT = '30'
        this.grupodisabled = true;
        this.nomeQuadradoSelecionado = "!MSGAUT1";
        this.getTexto();
      }
    },
    perMsgAut2() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.grupodisabled = true;
        this.temporizadorT = '60'
        this.nomeQuadradoSelecionado = "!MSGAUT2";
        this.getTexto();
      }
    },
    perAnuncio() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.grupodisabled = true;
        this.nomeQuadradoSelecionado = "!ANUNCIO";
        this.getTexto();
      }
    },

    perTabela() {
      if (!this.verifyGroup()) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Selecione o Grupo!!",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Selecione o grupo primeiro.",
          },
        });
      } else {
        this.quadrados.tabela = false;
        this.quadrados.anuncio = false;
        this.quadrados.info = false;
        this.atualizarfUNC = true;
        this.grupodisabled = true;
        this.nomeQuadradoSelecionado = "!TABELA";
        this.getTexto();
      }
    },

    getTexto() {
      const funcao = this.nomeQuadradoSelecionado;
      const idgrupo = this.grupo;
      funcs.getTexto(idgrupo, funcao).then((ef) => {
        if (ef.data[0]) {
          this.texto = new Buffer(ef.data[0].message, "base64").toString(
            "UTF-8"
          );

        }
      });
    },
    atualizaFuncao() {
      const funcao = this.nomeQuadradoSelecionado;

      const texto = new Buffer(this.texto).toString("base64");
      const image = this.base64;
      const timer = this.temporizadorT

      const idgrupo = this.grupo;
      funcs
        .atualizaFuncao(user.id, idgrupo, funcao, texto, image, timer)
        .then((ef) => {
          if (ef.data) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  icon: "CheckIcon",
                  text: "Atualizado com sucesso!",
                  variant: "success",
                },
              },
              {
                position: "bottom-right",
              }
            );
          }
        });
    },
  },
};
</script>

<style></style>
